import { useMemo } from "react";

import { ApolloQueryResult } from "@apollo/client";
import { ColDef } from "ag-grid-community";
import { message } from "antd";
import { useTranslation } from "react-i18next";

import { COLUMN_TYPE_NAMES } from "constants/gridConstants";
import {
  ComponentFormatEnum,
  DistributionBoardComponentDto,
  GetPackageBoardQuery,
  GetPackageBoardQueryVariables,
  GetProcessedDataHeaderNamesQuery,
  GetProcessedDataHeaderNamesQueryVariables,
  LocationByClientIdDto,
  useUpdateDistributionBoardsMutation,
} from "graphql/main";

const usePackageBoardColDefs = (
  boardDataId: any,
  locationId: LocationByClientIdDto["id"],
  refetchBoard: (
    variables?: Partial<GetPackageBoardQueryVariables>,
  ) => Promise<ApolloQueryResult<GetPackageBoardQuery>>,
  refetchProcessedDataHeaders: (
    variables?: Partial<GetProcessedDataHeaderNamesQueryVariables>,
  ) => Promise<ApolloQueryResult<GetProcessedDataHeaderNamesQuery>>,
) => {
  const { t } = useTranslation();
  const [updateDistributionBoard] = useUpdateDistributionBoardsMutation({
    onCompleted: async () => {
      await refetchBoard(locationId);
      await refetchProcessedDataHeaders();

      message.success(t("board_updated_successfully"));
    },
  });
  const localizedFormats = useMemo(
    () => ({
      [ComponentFormatEnum.AmperageSensor]: t(
        ComponentFormatEnum.AmperageSensor,
      ),
      [ComponentFormatEnum.CommunicationModule]: t(
        ComponentFormatEnum.CommunicationModule,
      ),
      [ComponentFormatEnum.Controller]: t(ComponentFormatEnum.Controller),
      [ComponentFormatEnum.DigitalInput]: t(ComponentFormatEnum.DigitalInput),
      [ComponentFormatEnum.Display]: t(ComponentFormatEnum.Display),
      [ComponentFormatEnum.Dummy]: t(ComponentFormatEnum.Dummy),
      [ComponentFormatEnum.LevelSensor1]: t(ComponentFormatEnum.LevelSensor1),
      [ComponentFormatEnum.LevelSensor2]: t(ComponentFormatEnum.LevelSensor2),
      [ComponentFormatEnum.Module]: t(ComponentFormatEnum.Module),
      [ComponentFormatEnum.PressureSensor]: t(
        ComponentFormatEnum.PressureSensor,
      ),
      [ComponentFormatEnum.Relay]: t(ComponentFormatEnum.Relay),
      [ComponentFormatEnum.VirtualFactor1Number]: t(
        ComponentFormatEnum.VirtualFactor1Number,
      ),
      [ComponentFormatEnum.VirtualFactor6Number]: t(
        ComponentFormatEnum.VirtualFactor6Number,
      ),
      [ComponentFormatEnum.VirtualFactor10Bar]: t(
        ComponentFormatEnum.VirtualFactor10Bar,
      ),
      [ComponentFormatEnum.VirtualFactor10Number]: t(
        ComponentFormatEnum.VirtualFactor10Number,
      ),
      [ComponentFormatEnum.VirtualFactor100Number]: t(
        ComponentFormatEnum.VirtualFactor100Number,
      ),
      [ComponentFormatEnum.VirtualFactor300Number]: t(
        ComponentFormatEnum.VirtualFactor300Number,
      ),
      [ComponentFormatEnum.VirtualFactor1000Number]: t(
        ComponentFormatEnum.VirtualFactor1000Number,
      ),
      [ComponentFormatEnum.VirtualOkNokBool]: t(
        ComponentFormatEnum.VirtualOkNokBool,
      ),
      [ComponentFormatEnum.VirtualOnOffBool]: t(
        ComponentFormatEnum.VirtualOnOffBool,
      ),
      [ComponentFormatEnum.VirtualYesNoBool]: t(
        ComponentFormatEnum.VirtualYesNoBool,
      ),
      [ComponentFormatEnum.VoltageSensor]: t(ComponentFormatEnum.VoltageSensor),
      [ComponentFormatEnum.VirtualInput3000Number]: t(
        ComponentFormatEnum.VirtualInput3000Number,
      ),
      [ComponentFormatEnum.VirtualInput1500Number]: t(
        ComponentFormatEnum.VirtualInput1500Number,
      ),
      [ComponentFormatEnum.VirtualInput1000Number]: t(
        ComponentFormatEnum.VirtualInput1000Number,
      ),
      [ComponentFormatEnum.VirtualInput750Number]: t(
        ComponentFormatEnum.VirtualInput750Number,
      ),
      [ComponentFormatEnum.VirtualInput600Number]: t(
        ComponentFormatEnum.VirtualInput600Number,
      ),
      [ComponentFormatEnum.VirtualInput500Number]: t(
        ComponentFormatEnum.VirtualInput500Number,
      ),
      [ComponentFormatEnum.VirtualInput300Number]: t(
        ComponentFormatEnum.VirtualInput300Number,
      ),
      [ComponentFormatEnum.VirtualInput187_5Number]: t(
        ComponentFormatEnum.VirtualInput187_5Number,
      ),
      [ComponentFormatEnum.VirtualInput150Number]: t(
        ComponentFormatEnum.VirtualInput150Number,
      ),
      [ComponentFormatEnum.VirtualInput120Number]: t(
        ComponentFormatEnum.VirtualInput120Number,
      ),
      [ComponentFormatEnum.VirtualInput100Number]: t(
        ComponentFormatEnum.VirtualInput100Number,
      ),
      [ComponentFormatEnum.VirtualInput60Number]: t(
        ComponentFormatEnum.VirtualInput60Number,
      ),
      [ComponentFormatEnum.VirtualInput50Number]: t(
        ComponentFormatEnum.VirtualInput50Number,
      ),
      [ComponentFormatEnum.VirtualInput33_333Number]: t(
        ComponentFormatEnum.VirtualInput33_333Number,
      ),
      [ComponentFormatEnum.VirtualInput30Number]: t(
        ComponentFormatEnum.VirtualInput30Number,
      ),
      [ComponentFormatEnum.VirtualInput7_5Number]: t(
        ComponentFormatEnum.VirtualInput7_5Number,
      ),
    }),
    [t],
  );
  const columnDefs = useMemo<ColDef<DistributionBoardComponentDto>[]>(
    () => [
      {
        cellEditor: "agSelectCellEditor",
        cellEditorParams: {
          values: Object.values(ComponentFormatEnum),
        },
        editable: true,
        field: "format",
        headerName: t("device"),
        minWidth: 220,
        onCellValueChanged: async (params) => {
          await updateDistributionBoard({
            variables: {
              input: {
                components: [
                  {
                    description: params.data.description,
                    format: params.newValue,
                    id: params.data.id,
                  },
                ],
                id: boardDataId,
              },
            },
          });
        },
        type: COLUMN_TYPE_NAMES.TEXT_COLUMN,
        valueFormatter: (params) =>
          localizedFormats[params.value as keyof typeof localizedFormats] ||
          params.value,
      },
      {
        cellStyle: { borderRight: "none" },
        editable: true,
        field: "description",
        headerName: t("description"),
        minWidth: 320,
        onCellValueChanged: async (params) => {
          await updateDistributionBoard({
            variables: {
              input: {
                components: [
                  {
                    description: params.newValue,
                    format: params.data.format,
                    id: params.data.id,
                  },
                ],
                id: boardDataId,
              },
            },
          });
        },
        type: COLUMN_TYPE_NAMES.TEXT_COLUMN,
      },
    ],
    [t, updateDistributionBoard, boardDataId, localizedFormats],
  );
  const columnDefsWithHeaders = useMemo<
    ColDef<DistributionBoardComponentDto>[]
  >(() => {
    return columnDefs;
  }, [columnDefs]);

  return { columnDefs: columnDefsWithHeaders };
};

export default usePackageBoardColDefs;
