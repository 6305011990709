import { FC, useState } from "react";

import { GetRowIdParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Button, Col, Form, Input, message, Row, Select } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import {
  getGridDefaultProps,
  GRID_DEFAULT_COL_DEFS,
} from "constants/gridConstants";
import {
  CommunicationModuleEnum,
  DisplayEnum,
  DistributionBoardComponentDto,
  LocationByClientIdDto,
  TemplateEnum,
  UpdateLocationInput,
  useGetDistributionBoardQuery,
  useGetProcessedDataHeaderNamesLazyQuery,
  useUpdateDistributionBoardsMutation,
} from "graphql/main";
import useDistributionBoardColDefs from "screens/Private/Client/components/distributionBoard/useDistributionBoardColDefs";

const StyledGrid = styled(AgGridReact)`
  .ag-row {
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ag-header-cell-comp-wrapper {
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const DistributionBoardTab: FC<{
  locationId: LocationByClientIdDto["id"];
}> = ({ locationId }) => {
  const { t } = useTranslation();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [form] = Form.useForm<UpdateLocationInput>();
  const { columnDefs } = useDistributionBoardColDefs();
  const { data: boardData, refetch } = useGetDistributionBoardQuery({
    onCompleted: async () => {
      form.resetFields();
    },
    variables: { locationId: locationId },
  });

  const [, { refetch: refetchProcessedDataHeaders }] =
    useGetProcessedDataHeaderNamesLazyQuery({
      variables: { locationId },
    });

  const [updateDistributionBoard] = useUpdateDistributionBoardsMutation({
    onCompleted: async () => {
      await refetch(locationId);
      await refetchProcessedDataHeaders();

      message.success(t("board_updated_successfully"));
    },
  });

  return (
    <Row style={{ height: "100%", width: "100%" }}>
      <Col style={{ marginTop: "1rem" }} xs={24} md={6}>
        {boardData?.distributionBoard && (
          <Form
            onChange={() => setIsDisabled(false)}
            form={form}
            initialValues={boardData.distributionBoard}
            onFinish={async (values) => {
              await updateDistributionBoard({
                variables: {
                  input: values,
                },
              });
            }}
          >
            <Form.Item name="id" label={t("board_id")}>
              <Input style={{ backgroundColor: "lightgray" }} readOnly />
            </Form.Item>
            <Form.Item name="template" label={t("template")}>
              <Select
                onSelect={() => setIsDisabled(false)}
                optionFilterProp="label"
                options={Object.keys(TemplateEnum).map((template) => ({
                  label: template,
                  value: TemplateEnum[template as keyof typeof TemplateEnum],
                }))}
              />
            </Form.Item>
            <Form.Item name="powerSupply" label={t("power_supply")}>
              <Input />
            </Form.Item>
            <Form.Item
              name="communicationModule"
              label={t("communication_module")}
            >
              <Select
                onSelect={() => setIsDisabled(false)}
                optionFilterProp="label"
                options={Object.keys(CommunicationModuleEnum).map((module) => ({
                  label: module,
                  value:
                    CommunicationModuleEnum[
                      module as keyof typeof CommunicationModuleEnum
                    ],
                }))}
              />
            </Form.Item>
            <Form.Item name="display" label={t("display")}>
              <Select
                onSelect={() => setIsDisabled(false)}
                optionFilterProp="label"
                options={Object.keys(DisplayEnum).map((module) => ({
                  label: t(module),
                  value: DisplayEnum[module as keyof typeof DisplayEnum],
                }))}
              />
            </Form.Item>
            <Form.Item>
              <Button
                disabled={isDisabled}
                type="primary"
                style={{ width: "100%" }}
                htmlType="submit"
              >
                {t("save")}
              </Button>
            </Form.Item>
          </Form>
        )}
      </Col>
      <Col xs={24} md={{ offset: 1, span: 17 }} style={{ height: "100%" }}>
        <StyledGrid
          getRowId={(params: GetRowIdParams<DistributionBoardComponentDto>) =>
            params.data.id
          }
          rowData={boardData?.distributionBoard.boardComponents}
          domLayout="autoHeight"
          rowHeight={22}
          headerHeight={25}
          onCellEditingStopped={async (e) => {
            e.valueChanged &&
              (await updateDistributionBoard({
                variables: {
                  input: {
                    components: [
                      {
                        description: e.newValue,
                        format: e.data.format,
                        id: e.data.id,
                      },
                    ],
                    id: boardData?.distributionBoard.id,
                  },
                },
              }));
          }}
          defaultColDef={{
            ...GRID_DEFAULT_COL_DEFS.defaultColDef,
            cellStyle: { borderRight: "1px solid #bdc3c7" },
            wrapHeaderText: true,
          }}
          columnDefs={columnDefs}
          {...getGridDefaultProps({ includeDefaultColDefs: false })}
        />
      </Col>
    </Row>
  );
};
export default DistributionBoardTab;
